.panel {
    height: calc(100vh - 78px - 1em);
    position: relative;
}

.content {
    display: flex;
    justify-content: space-between;
}

.table {
    flex: 0 1 50%;
    overflow-y: scroll;
}

.resultInfo {
    background-color: #fdc748;
    padding: 0.5em;
}

.doctor {
    flex-shrink: 1;
    flex-basis: 28%;
}

.balance {
    flex-shrink: 1;
    flex-basis: 8%;
    text-align: right;
}

.amount {
    flex-shrink: 1;
    flex-basis: 8%;
    text-align: right;
}

.canLockOutEvening {
    flex-shrink: 1;
    flex-basis: 14%;
}

.lockWeekDays {
    flex-shrink: 1;
    flex-basis: 15%;
    text-align: right;
}

.lockWeekendDays {
    flex-shrink: 1;
    flex-basis: 23%;
    text-align: right;
}

.isOk {
    color: green;
}

.hasError {
    color: var(--clr-afRed);
}

.available_doctor {
    flex-shrink: 1;
    flex-basis: 100%;
}

.notes {
    padding: 1em;
}

.search {
    padding: 0.5em 0.75em;
}

.showOnlyDoctors {
    padding-top: 0.5em;
}
