.header {
    display: flex;
    align-items: center;
    column-gap: 0.25em;
    padding-top: 1px;
    padding-bottom: 0.5em;
}

.booked {
    display: flex;
    flex-direction: column;
    row-gap: 0.25em;
}

.arrow {
    width: 16px;
    fill: var(--clr-afGray);
}

.rollingEyes {
    width: 18px;
}
