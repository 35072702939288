.content {
    padding: 1em;
}

.cols {
    display: flex;
    justify-content: space-between;
}

.cols > * {
    flex: 0 0 33%;
}

.twoCols > *:last-child {
    flex: 0 0 66%;
}

.number {
    width: 5em;
    text-align: right;
    margin-right: 0.25em;
}

.rows {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
}
