.service {
	display: flex;
	justify-content: space-between;
	border: 1px solid var(--clr-afGray);
	border-radius: var(--br_small);
	padding: 0.25em 0.5em;
	align-items: center;
}

.booked {
	flex-basis: 6%;
}

.dayName {
	flex-basis: 9%;
}

.date {
	flex-basis: 39%;
	white-space: nowrap;
}

.points {
	flex-basis: 8%;
	text-align: right;
}

.serviceTemplate {
	flex-basis: 38%;
	display: flex;
	align-items: center;
	column-gap: 6px;
}

.grouped {
	background: repeating-linear-gradient(135deg, var(--clr-lightGray), var(--clr-lightGray) 5px, rgba(255, 255, 255, 0.8) 5px, rgba(255, 255, 255, 0.8) 10px);
}

.publicHoliday {
	position: relative;
	overflow: hidden;
}

.publicHoliday::before {
	content: "";
	position: absolute;
	left: 0;
	top: 0;
	width: 12px;
	height: 10px;
	border-bottom-right-radius: 3px;
	background-color: #b6d767 !important;
}
