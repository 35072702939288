.fieldset {
    border: 1px solid var(--clr-gray);
    border-radius: var(--br_small);
    padding: 0.5em 1em;
    margin-bottom: 1em;
}

.fieldset:last-child {
    margin-bottom: 0;
}

.fieldset > legend {
    background-color: white;
    padding: 0 0.25em;
    margin-left: -0.25em;
}

fieldset > legend > label {
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: 6px;
}

.fieldset.hide {
    border: none;
    padding: 0;
}

.fieldset.hide > legend {
    padding: 0;
    margin: 0;
}

.fieldset.hide > div {
    display: none;
}
