.note {
	background-color: #fee3a5;
	border: 1px solid #fdc748;
	padding: 0.5em;
	border-radius: var(--br_small);
	font-size: 0.875em;
}

.marginTop {
	margin-top: 1em;
}

.marginBottom {
	margin-bottom: 1em;
}

.note ul {
	list-style-type: disc;
	margin-left: 1.3em;
}
