*,
html {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

html {
	height: 100%;
}

:root {
	--clr-afGray: #535557;
	--clr-afRed: #d8011e;
	--clr-afBlue: #306ab2;
	--clr-lightBlue: #bfd1e7;
	--clr-lightGray: #f2f2f2;
	--clr-gray: #e2e2e2;
	--clr-darkGray: #aaa;
	--clr-green: #469c5f;
	--clr-lightGreen: #c6e0ce;
	--clr-red: #f7ccd2;
	--clr-planning: #fdc748;

	--br_large: 0.5rem;
	--br_small: 5px;
}

body {
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 16px;
	line-height: 1.3em;
	color: var(--clr-afGray);
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	padding: 78px 1em 1em 1em;
	position: relative;
	background-color: var(--clr-afGray);
}

a {
	color: var(--clr-afRed);
	text-decoration: none;
	font-weight: 600;
}

a:hover {
	text-decoration: underline;
}

textarea,
input,
select {
	width: 100%;
	font-family: "Open Sans", sans-serif;
	font-weight: 400;
	font-size: 16px;
	color: var(--clr-afGray);
	line-height: 1em;
	border: none;
	border-radius: var(--br_small);
	background-color: var(--clr-lightGray);
}

textarea,
input {
	padding: 0.5em;
}

select {
	padding: 4px 8px;
	border-radius: var(--br_large);
	border: 1px solid var(--clr-afGray);
}

input[type="checkbox"],
input[type="radio"] {
	width: auto;
}

input[type="text"]:focus,
textarea:focus {
	outline: 2px solid var(--clr-afBlue);
	outline-offset: -2px;
}

textarea {
	line-height: 1.3em;
	resize: none;
}

::-webkit-input-placeholder {
	color: #aaa;
}

:-ms-input-placeholder {
	color: #aaa;
}

::placeholder {
	color: #aaa;
}

button,
input[type="submit"] {
	font-size: 1em;
	background-color: var(--clr-lightBlue);
	border: none;
	color: var(--clr-afGray);
	padding: 0 16px;
	line-height: 2.5em;
	text-decoration: none;
	cursor: pointer;
	border-radius: var(--br_small);
	outline: none;
}

button:not(:disabled):hover {
	background-color: var(--clr-afBlue);
	color: white;
}

button:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

button.add {
	background-color: #090;
	padding: 0;
	width: 21px;
	height: 21px;
	border-radius: 50%;
	line-height: 21px;
	font-size: 21px;
}

button.add:hover {
	transform: scale(1.2);
}

/* button {
	background: transparent linear-gradient(106deg, #5995d2 0%, #aab76b 25%, #8e439e 49%, #84bcb3 77%, #09776c 100%) 0% 0% no-repeat padding-box;
} */

h3 {
	font-weight: 600;
	font-size: 1.2em;
}

h4 {
	font-weight: 600;
	font-size: 1em;
}

label.fieldlabel > span {
	line-height: 1.2em;
	font-size: 0.8em;
	font-weight: 600;
}

.required > span {
	background-color: #90ee90;
	padding: 0 4px;
	border-radius: 4px;
}

span.small {
	font-size: 0.8em;
}

strong {
	font-weight: 600;
}

#map {
	height: 100%;
	flex-basis: 50%;
	flex-grow: 10;
	flex-shrink: 10;
}

form.login {
	padding: 1em;
}

form.login label {
	display: flex;
	align-items: center;
	margin-bottom: 1em;
}

form.login label span {
	display: block;
	width: 20%;
}

form.login label input {
	width: 80%;
}

form.login p {
	text-align: right;
	margin-top: 1em;
}

div.spinner {
	position: fixed;
	z-index: 100;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding: 0 2em;
	background-color: rgba(0, 0, 0, 0.4);
	overflow-y: scroll;
}

span.click {
	color: var(--clr-afRed);
	font-weight: 600;
}

span.click:hover {
	cursor: pointer;
	text-decoration: underline;
}

.sk-circle {
	margin: 100px auto;
	width: 40px;
	height: 40px;
	position: relative;
}
.sk-circle .sk-child {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
}
.sk-circle .sk-child::before {
	content: "";
	display: block;
	margin: 0 auto;
	width: 15%;
	height: 15%;
	background-color: white;
	border-radius: 100%;
	-webkit-animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
	animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
}
.sk-circle .sk-circle2 {
	-webkit-transform: rotate(30deg);
	-ms-transform: rotate(30deg);
	transform: rotate(30deg);
}
.sk-circle .sk-circle3 {
	-webkit-transform: rotate(60deg);
	-ms-transform: rotate(60deg);
	transform: rotate(60deg);
}
.sk-circle .sk-circle4 {
	-webkit-transform: rotate(90deg);
	-ms-transform: rotate(90deg);
	transform: rotate(90deg);
}
.sk-circle .sk-circle5 {
	-webkit-transform: rotate(120deg);
	-ms-transform: rotate(120deg);
	transform: rotate(120deg);
}
.sk-circle .sk-circle6 {
	-webkit-transform: rotate(150deg);
	-ms-transform: rotate(150deg);
	transform: rotate(150deg);
}
.sk-circle .sk-circle7 {
	-webkit-transform: rotate(180deg);
	-ms-transform: rotate(180deg);
	transform: rotate(180deg);
}
.sk-circle .sk-circle8 {
	-webkit-transform: rotate(210deg);
	-ms-transform: rotate(210deg);
	transform: rotate(210deg);
}
.sk-circle .sk-circle9 {
	-webkit-transform: rotate(240deg);
	-ms-transform: rotate(240deg);
	transform: rotate(240deg);
}
.sk-circle .sk-circle10 {
	-webkit-transform: rotate(270deg);
	-ms-transform: rotate(270deg);
	transform: rotate(270deg);
}
.sk-circle .sk-circle11 {
	-webkit-transform: rotate(300deg);
	-ms-transform: rotate(300deg);
	transform: rotate(300deg);
}
.sk-circle .sk-circle12 {
	-webkit-transform: rotate(330deg);
	-ms-transform: rotate(330deg);
	transform: rotate(330deg);
}
.sk-circle .sk-circle2::before {
	-webkit-animation-delay: -1.1s;
	animation-delay: -1.1s;
}
.sk-circle .sk-circle3::before {
	-webkit-animation-delay: -1s;
	animation-delay: -1s;
}
.sk-circle .sk-circle4::before {
	-webkit-animation-delay: -0.9s;
	animation-delay: -0.9s;
}
.sk-circle .sk-circle5::before {
	-webkit-animation-delay: -0.8s;
	animation-delay: -0.8s;
}
.sk-circle .sk-circle6::before {
	-webkit-animation-delay: -0.7s;
	animation-delay: -0.7s;
}
.sk-circle .sk-circle7::before {
	-webkit-animation-delay: -0.6s;
	animation-delay: -0.6s;
}
.sk-circle .sk-circle8::before {
	-webkit-animation-delay: -0.5s;
	animation-delay: -0.5s;
}
.sk-circle .sk-circle9::before {
	-webkit-animation-delay: -0.4s;
	animation-delay: -0.4s;
}
.sk-circle .sk-circle10::before {
	-webkit-animation-delay: -0.3s;
	animation-delay: -0.3s;
}
.sk-circle .sk-circle11::before {
	-webkit-animation-delay: -0.2s;
	animation-delay: -0.2s;
}
.sk-circle .sk-circle12::before {
	-webkit-animation-delay: -0.1s;
	animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

@keyframes sk-circleBounceDelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

div.error,
div.success,
div.tempError > div,
div.tempSuccess > div {
	font-weight: 600;
	background-color: #090;
	color: white;
	padding: 0.5em 1em;
}

div.tempError,
div.tempSuccess {
	position: fixed;
	top: -0;
	transform: translateY(-100%);
	left: 50%;
	width: 300px;
	margin-left: -150px;
	text-align: center;
	z-index: 10;
	transition: transform 0.25s ease-in-out;
}

div.tempSuccess.show,
div.tempError.show {
	transform: translateY(0);
}

div.tempError > div,
div.tempSuccess > div {
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
}

div.tempError > div,
div.error {
	background-color: var(--clr-afRed);
}

form.login div.error {
	padding: 1em;
}

div.close {
	position: absolute;
	left: -16px;
	top: -16px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background-color: var(--clr-afRed);
	color: white;
	text-align: center;
	line-height: 32px;
	cursor: pointer;
	font-size: 1.5em;
	transition: all 0.2s ease-in-out;
	z-index: 1;
}

div.close:hover {
	transform: scale(1.2);
}

label.checkbox {
	line-height: 1.5em;
	padding-top: 0.75em;
	display: block;
	font-size: 0.9em;
	white-space: nowrap;
}

label.radio > input,
label.checkbox > input {
	vertical-align: baseline;
	margin-right: 6px;
}

label.checkbox:hover,
label.radio:hover {
	cursor: pointer;
}

label.radio {
	display: inline-block;
	line-height: 2em;
	margin-right: 1em;
	white-space: nowrap;
}

@keyframes ldio-dyw8t9yacb-o {
	0% {
		opacity: 1;
		transform: translate(0 0);
	}
	49.99% {
		opacity: 1;
		transform: translate(44px, 0);
	}
	50% {
		opacity: 0;
		transform: translate(44px, 0);
	}
	100% {
		opacity: 0;
		transform: translate(0, 0);
	}
}
@keyframes ldio-dyw8t9yacb {
	0% {
		transform: translate(0, 0);
	}
	50% {
		transform: translate(44px, 0);
	}
	100% {
		transform: translate(0, 0);
	}
}
.ldio-dyw8t9yacb div {
	position: absolute;
	width: 44px;
	height: 44px;
	border-radius: 50%;
	top: 28px;
	left: 6px;
}
.ldio-dyw8t9yacb div:nth-child(1) {
	background: var(--clr-afRed);
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.ldio-dyw8t9yacb div:nth-child(2) {
	background: #01e53e;
	animation: ldio-dyw8t9yacb 7.142857142857142s linear infinite;
	animation-delay: 0s;
}
.ldio-dyw8t9yacb div:nth-child(3) {
	background: var(--clr-afRed);
	animation: ldio-dyw8t9yacb-o 7.142857142857142s linear infinite;
	animation-delay: -3.571428571428571s;
}
.loadingio-spinner-dual-ball-o20bp2k35w {
	width: 32px;
	height: 32px;
	display: inline-block;
	overflow: hidden;
	vertical-align: middle;
}
.ldio-dyw8t9yacb {
	width: 100%;
	height: 100%;
	position: relative;
	transform: translateZ(0) scale(0.32);
	backface-visibility: hidden;
	transform-origin: 0 0; /* see note above */
}
.ldio-dyw8t9yacb div {
	box-sizing: content-box;
}

div.header {
	padding: 0 1em;
	background-color: var(--clr-afGray);
	color: white;
	line-height: 2.5em;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 1em;
}

ul.selectList div.header {
	border-top-left-radius: var(--br_small);
}

ul {
	list-style: none;
}

ul.selectList div.title {
	padding: 0.25em 0.5em;
	display: flex;
	justify-content: space-between;
	position: relative;
}

ul.selectList div.symbols {
	display: flex;
	justify-content: space-between;
	column-gap: 4px;
}

div.plans {
	padding-left: 6px;
	padding-bottom: 6px;
}

li.noContent {
	padding: 0.25em 0.5em;
	font-style: italic;
	font-size: 0.9em;
}

ul.plans {
	background-color: white;
}

ul.plans li {
	cursor: pointer;
	padding: 0.25em 0.5em;
}

ul.plans li:hover {
	background-color: rgba(52, 107, 179, 0.3);
}

ul.plans li.selected {
	font-weight: 600;
}

div.areaColor {
	display: inline-block;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-right: 4px;
}

ul.selectList ul.selectList {
	border-left: 1px solid var(--clr-afGray);
	border-bottom: 1px solid var(--clr-afGray);
	border-bottom-left-radius: var(--br_small);
}

ul.selectList ul.selectList > li:last-child {
	border-bottom-left-radius: calc(var(--br_small) - 1px);
}

ul.selectList > li:nth-child(odd) {
	background-color: #e9e9e9;
}
ul.selectList > li:nth-child(even) {
	background-color: var(--clr-lightGray);
}

ul.selectList > li {
	position: relative;
}

ul.selectList > li.selected {
	background-color: #c2d2e8;
}

ul.selectList div.title:hover {
	cursor: pointer;
}

ul.selectList li:not(.selected) div.title:hover {
	background-color: rgba(52, 107, 179, 0.3);
}

svg.triangle {
	width: 8px;
	height: 13px;
	opacity: 0.8;
	fill: var(--clr-afGray);
}

div.addCategory {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	padding-top: 8em;
	background-color: rgba(0, 0, 0, 0.4);
	z-index: 10;
}

div.addCategory label {
	display: block;
	margin-bottom: 1em;
}

div.buttonRow {
	display: flex;
	justify-content: space-between;
}

ul.select div.floatError {
	position: absolute;
	z-index: 1;
	top: 100%;
	left: 0;
	right: 0;
	margin: 1px 0.5em 0 0.5em;
	background-color: yellow;
	color: var(--clr-afGray);
	font-size: 0.8em;
	line-height: 1.3em;
	font-weight: 600;
	border-radius: var(--br_small);
	padding: 0.25em 0.5em;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

#hoverSelector {
	position: absolute;
	z-index: 1;
	left: 1px;
	top: 0;
	right: 0;
	display: flex;
	background-color: rgba(0, 0, 0, 0.05);
}

#hoverSelector ul {
	background-color: #e9e9e9;
	min-width: 200px;
	box-shadow: 3px 3px 10px rgb(0 0 0 / 20%);
}

div.note {
	text-align: center;
	padding: 2px 0;
}

span.note {
	display: inline-block;
	background-color: yellow;
	padding: 0.5em;
	box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
	border-radius: var(--br_small);
	line-height: 1.5em;
	margin-bottom: 1em;
}

button svg.triangle {
	fill: white;
	opacity: 1;
	margin: 0 0.5em;
}

div.serviceCircles {
	position: absolute;
	z-index: 1;
	right: 1px;
	top: 1px;
	width: 300px;
	background-color: rgba(255, 255, 255, 0.9);
	border-bottom-left-radius: var(--br_large);
	overflow: hidden;
}

div.serviceCircles.zip {
	bottom: 1px;
	border-bottom-left-radius: 0;
}

div.serviceCircles li {
	display: flex;
	column-gap: 1em;
	justify-content: space-between;
	align-items: center;
	padding: 0 0.5em;
	cursor: default;
}

div.zipMover {
	position: absolute;
	left: 0;
	top: 32px;
	right: 0;
	bottom: 0;
	overflow: hidden;
}

div.zipMover div.headerCols {
	display: flex;
	justify-content: space-between;
}

div.zipMover div.headerCols > div {
	width: 50%;
	white-space: nowrap;
	background-color: var(--clr-afGray);
	color: white;
	font-size: 0.8em;
	font-weight: 600;
	padding: 0 0.5rem;
}

div.zipMover div.headerCols > div:last-child {
	border-left: 1px solid rgba(255, 255, 255, 0.5);
}

div.zipMover ul {
	position: absolute;
	left: 0;
	top: 59px;
	right: 50%;
	bottom: 0;
	font-size: 0.8em;
	line-height: 1.4em;
	overflow-y: scroll;
	white-space: nowrap;
	overflow-x: hidden;
	text-overflow: ellipsis;
}

div.zipMover li:hover {
	cursor: pointer;
	background-color: rgba(52, 107, 179, 0.3);
}

div.zipMover ul.excluded {
	left: 50%;
	right: 0;
	border-left: 1px solid rgba(0, 0, 0, 0.3);
}

div.zipMover li.selected {
	background-color: rgba(216, 1, 30, 1);
	color: white;
	font-weight: 600;
}

div.zipMover div.search {
	padding: 0.25rem;
	background-color: var(--clr-afGray);
}

div.zipMover li button {
	padding: 0 8px;
	margin: 0.25rem auto;
	line-height: 1.5em;
	font-size: 1em;
}

div.zipMover div.headerCols button:disabled {
	background-color: #999;
	opacity: 0.8;
}

div.zipMover li button svg {
	fill: white;
	width: 12px;
	height: 12px;
	vertical-align: middle;
}

div.calendarMonth {
	flex: 1 0 30%;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	border-radius: var(--br_small);
	border: 1px solid var(--clr-afGray);
	background-color: var(--clr-lightGray);
	min-width: 200px;
	gap: 1px;
}

div.calendarMonth > div {
	flex: 1 0 13%;
	text-align: center;
	padding: 1px;
	background-color: white;
	font-size: 0.85em;
	line-height: 2em;
}

div.calendarMonth > div.click:hover {
	outline: #999 dashed 1px;

	cursor: pointer;
}

div.calendarMonth > div:nth-child(7n + 1),
div.calendarMonth > div:nth-child(7n + 0) {
	background-color: rgba(0, 0, 0, 0.05);
}

div.calendarMonth > div.disabled {
	color: #aaa;
}

div.calendarMonth > nav {
	display: flex;
	width: 100%;
	justify-content: space-between;
	align-items: center;
	background-color: var(--clr-afGray);
}

div.calendarMonth svg {
	width: 16px;
	height: 16px;
	fill: white;
	margin: 0 8px;
}

div.calendarMonth svg:hover {
	cursor: pointer;
}

div.calendarMonth svg:first-child:hover {
	margin-left: 7px;
	margin-right: 9px;
}

div.calendarMonth svg:last-child:hover {
	margin-left: 9px;
	margin-right: 7px;
}

div.calendarMonth > nav > span,
div.calendarMonth > span {
	display: block;
	text-align: center;
	width: 100%;
	background-color: var(--clr-afGray);
	color: white;
	line-height: 2em;
}

div.sidePanel {
	width: 250px;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	row-gap: 1em;
	justify-content: flex-start;
}

ul.planSelector li {
	display: flex;
	align-items: center;
	column-gap: 0.5em;
	padding: 1px 0.5em;
	line-height: 1.5em;
	overflow: hidden;
	text-overflow: ellipsis;
}

ul.planSelector li:hover {
	cursor: pointer;
	background-color: rgba(52, 107, 179, 0.3);
}

ul.planSelector li.selected {
	background-color: var(--clr-afRed);
	color: white;
	font-weight: 600;
}

div.colorCircle {
	width: 13px;
	height: 13px;
	border-radius: 50%;
}

div.buttons {
	padding: 1em;
	display: flex;
	justify-content: flex-end;
	column-gap: 1em;
}

div.smallPanel header {
	position: relative;
	background-color: var(--clr-afBlue);
	color: white;
	text-align: center;
	border-top-left-radius: var(--br_small);
	border-top-right-radius: var(--br_small);
	font-size: 0.8em;
	font-weight: 600;
	line-height: 2em;
}

div.smallPanel header button {
	position: absolute;
	right: 4px;
	top: 2px;
}

div.smallPanel div.search {
	padding: 4px 2px;
	background-color: var(--clr-afGray);
}

div.smallPanel div.content {
	border: 1px solid var(--clr-afGray);
	border-top: none;
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	max-height: calc(100% - 27px);
	overflow: hidden;
}

div.smallPanel > div,
div.smallPanel > ul {
	border-left: 1px solid var(--clr-afGray);
	border-right: 1px solid var(--clr-afGray);
}

div.smallPanel > *:nth-child(2) {
	border-top: 1px solid white;
}

div.smallPanel > *:last-child {
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
	border-bottom: 1px solid var(--clr-afGray);
}

ul.scroll {
	overflow-y: scroll;
	max-height: calc(100% - 101px);
}

div.tabs {
	display: flex;
	justify-content: space-between;
	column-gap: 1px;
	align-items: flex-end;
	font-size: 0.8em;
	padding-top: 4px;
}

div.tabs > div {
	flex-grow: 10;
	position: relative;
	padding: 0 6px;
	background-color: var(--clr-lightBlue);
	border-top-left-radius: var(--br_small);
	border-top-right-radius: var(--br_small);
	line-height: 1.8em;
	cursor: pointer;
	transition: padding 0.1s ease-in-out;
	white-space: nowrap;
	text-overflow: ellipsis;
}

div.tabs > div:hover {
	padding-bottom: 8px;
}

div.tabs div.empty {
	color: #999;
}

div.tabs div.selected {
	background-color: var(--clr-afBlue);
	color: white;
	font-weight: 600;
	padding-top: 4px;
	padding-bottom: 4px;
}

div.tabs div.selected div.count {
	top: 8px;
}

div.tabs span {
	display: inline-block;
	width: 21px;
	height: 21px;
	border-radius: 50%;
	background-color: yellow;
	text-align: center;
	font-weight: 700;
	line-height: 21px;
	margin-right: 4px;
	color: var(--clr-afGray);
	font-family: "Times New Roman", Times, serif;
	font-style: italic;
}

ul.select {
	background-color: #efefef;
}

ul.select li {
	position: relative;
	padding: 0 0.5em;
	line-height: 1.6em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	border-bottom: 1px solid transparent;
	font-size: 0.9em;
}

ul.select li.edit {
	white-space: normal;
	overflow: visible;
	padding-top: 4px;
	padding-bottom: 4px;
}

ul.select li:nth-child(odd) {
	background-color: var(--clr-lightGray);
}

ul.select li:last-child {
	border-bottom-left-radius: var(--br_small);
	border-bottom-right-radius: var(--br_small);
}

ul.select li.selected {
	background-color: var(--clr-lightBlue);
	color: var(--clr-afBlue);
	display: flex;
	justify-content: space-between;
}

ul.select div.deleteBtn {
	cursor: pointer;
}

ul.select li.selected.multi {
	border-bottom-color: white;
}

ul.select li.button {
	text-align: center;
}

ul.select li button {
	padding: 0 8px;
	margin: 0.25rem auto;
	line-height: 1.6em;
	font-size: 1em;
	border-radius: var(--br_large);
}

ul.select li:not(.selected, .button, .none, .edit):hover {
	cursor: pointer;
	background-color: rgba(52, 107, 179, 0.3);
}

ul.select li.removeable:hover {
	cursor: pointer;
}

ul.select li.none {
	font-style: italic;
}

div.count {
	display: inline-block;
	vertical-align: top;
	margin-top: 2px;
	margin-left: 2px;
	background-color: orange;
	min-width: 18px;
	height: 18px;
	padding: 0 4px;
	border-radius: 9px;
	text-align: center;
	line-height: 18px;
	font-size: 0.8em;
	color: var(--clr-afGray);
	font-weight: 600;
}

div.createRoster {
	padding: 1em;
	background-color: white;
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
}

div.dateTime {
	position: relative;
	padding: 1em;
	background-color: white;
	border-bottom-left-radius: var(--br_large);
	border-bottom-right-radius: var(--br_large);
	display: flex;
	justify-content: space-between;
	column-gap: 1em;
}

div.windowBackground {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 2;
	padding-top: 3em;
	user-select: none;
}

div.instruction {
	background-color: #fdc748;
	padding: 0.75em 1em;
	border-radius: var(--br_small);
	border: 1px solid rgba(0, 0, 0, 0.3);
	margin-bottom: 1em;
}

div.instruction span {
	white-space: nowrap;
}
